import { IObservable, mkObservable, Observer } from "./Observable";
export const zip = <A, B>(left: IObservable<A>) => (right: IObservable<B>) => mkObservable<[A, B]>(observer => {
  var left_queue: A[] = [];
  var right_queue: B[] = [];
  const leftObserver: Observer<A> = {
    next: x => {
      left_queue.push(x);
      const r = right_queue.shift();
      if (typeof r !== "undefined") {
        const l = left_queue.shift() as A;
        observer.next([l, r]);
      }
    },
    error: err => observer.error(err),
    complete: () => observer.complete()
  };
  const rightObserver: Observer<B> = {
    next: x => {
      right_queue.push(x);
      const l = left_queue.shift();
      if (typeof l !== "undefined") {
        const r = right_queue.shift() as B;
        observer.next([l, r]);
      }
    },
    error: err => observer.error(err),
    complete: () => observer.complete()
  };
  const left_unsub = left.subscribe(leftObserver);
  const right_unsub = right.subscribe(rightObserver);
  const unsub = () => {
    left_unsub();
    right_unsub();
  };
  return unsub;
});
