export function getRootElement() {
  var element = document.documentElement;
  return "CSS1Compat" == document.compatMode ? element : document.body || element;
}

export function getViewportSize() {
  var rootElement = getRootElement();
  return [
    rootElement.clientWidth || window.innerWidth,
    rootElement.clientHeight || window.innerHeight
  ];
}

export function getDocumentScroll() {
  return [
    window.pageXOffset ||
    (document.documentElement && document.documentElement.scrollLeft) ||
    (document.body && document.body.scrollLeft) ||
    0,
    window.pageYOffset ||
    (document.documentElement && document.documentElement.scrollTop) ||
    (document.body && document.body.scrollTop) ||
    0
  ];
}

export function getEventPos(event: MouseEvent | Touch) {
  var b = getRootElement(),
    c = getDocumentScroll();
  return [
    precisionRound(event.pageX || event.clientX + c[0] - (b.clientLeft || 0) || 0, 1),
    precisionRound(event.pageY || event.clientY + c[1] - (b.clientTop || 0) || 0, 1)
  ];
}

export function getTouchMoveDistance([s, e]:  [Touch, Touch]) {
  return [
    Math.sign(e.clientX - s.clientX) * precisionRound(Math.abs(e.clientX - s.clientX), 1),
    Math.sign(e.clientY - s.clientY) * precisionRound(Math.abs(e.clientY - s.clientY), 1)
  ]
}

export function getEventTarget(event: UIEvent) {
  try {
    return event.target || event.srcElement;
  } catch (c) {
    console.warn(c);
  }
}

const startTime = new Date().valueOf()
export function getRelativeTime() {
  return precisionRound((startTime ? new Date().valueOf() - startTime : 0) / 1000, 1);
}

function precisionRound(number: number, precision: number) {
  var factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
};