import { IObservable, mkObservable } from "./Observable";
export const when = <T>(notifier: IObservable<any>) => (source: IObservable<T>) => mkObservable<T>(observer => {
  var buff: T | undefined;
  source.subscribe({
    next: i => {buff = i},
    error: err => observer.error(err),
    complete: () => void 8// observer.complete()
  });
  notifier.subscribe({
    next: () => {
      if(typeof buff != "undefined") {
        observer.next(buff);
        buff = undefined
      }
    },
    error: err => observer.error(err),
    complete: () => observer.complete()
  });
});
