import getHasLiedOs from "../Fingerprinting/getHasLiedOS";
import getHasLiedBrowser from "../Fingerprinting/getLiedBrowser";

export function mkPageView() {

  let n = window.navigator;

  const r = function (f: () => boolean | number | string | null) {
    try {
      var o = f();
      return typeof o == typeof true && o != null ? +o : o;
    } catch (e) {
      return null;
    }
  },
    ua = n.userAgent.toLowerCase(),
    re = "CSS1Compat" == document.compatMode
      ? document.documentElement
      : document.body || document.documentElement,
    pv = {
      t: "pageview",
      s: 0,
      tz: -new Date().getTimezoneOffset(),
      n: r(() => Notification.permission),
      f: r(() => window.top != window.self),
      nt: r(() => n.connection?.type || null),
      ns: r(() => n.connection?.effectiveType || null),
      l: r(() => {
        const a = window.navigator
          ? n.language ||
          n.userLanguage ||
          n.browserLanguage ||
          n.systemLanguage
          : null;
        return "[object String]" == Object.prototype.toString.call(a)
          ? a.toLowerCase()
          : null;
      }),
      lz: r(() => (n.languages || []).join(" ").toLowerCase()),
      vw: r(() => re.clientWidth || window.innerWidth),
      vh: r(() => re.clientHeight || window.innerHeight),
      sw: r(() => window.screen.width),
      sh: r(() => window.screen.height),
      sd: window.screen.colorDepth || null,
      sr: window.devicePixelRatio || null,
      np: r(() => n.platform || null),
      dnt: r(() => {
        if (typeof n.doNotTrack !== "undefined" && n.doNotTrack !== null) {
          if (n.doNotTrack == "yes") {
            return true;
          } else if (n.doNotTrack == "no") {
            return false;
          }
          return !!n.doNotTrack;
        } else if (typeof window.doNotTrack !== "undefined" &&
          window.doNotTrack !== null) {
          return !!window.doNotTrack;
        }
        return null;
      }),
      c: r(() => n.cookieEnabled),
      ss: r(() => !!window.sessionStorage),
      wd: r(() => !!n.webdriver),
      ls: r(() => !!window.localStorage),
      ib: r(() => !!window.indexedDB),
      od: r(() => !!window.openDatabase),
      hf: r(() => !!document.hasFocus()),
      iv: r(() => !!!document.hidden),
      ll: r(() => n.languages[0].substr(0, 2) !== n.language.substr(0, 2)),
      lr: r(() => window.screen.width < window.screen.availWidth ||
        window.screen.height < window.screen.availHeight),
      lo: r(getHasLiedOs),
      lb: r(getHasLiedBrowser)
    };

  return ([{ t: "pageview", s: 0, a: pv }]);

}
