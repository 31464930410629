import { throwError } from "../Observables/throwError";
import { fromEvent } from "../Observables/fromEvent";
import { startWith } from "../Observables/startWith";
import { map } from "../Observables/map";

export const mkVisibilityChange = () => !(
  "onvisibilitychange" in document
)
  ? throwError<boolean>("visibilitychange Not Supported")
  // : startWith({ hidden: document.hidden, init: true }, fromEvent(document, "visibilitychange"))
  : fromEvent(document, "visibilitychange").pipe(
    map(_e => !document.hidden)
  );