import  { IObservable, mkObservable } from "./Observable";
type EventName = keyof (HTMLElementEventMap & WindowEventMap & DocumentEventMap);

type FromEvent<T> = 
    (<K extends keyof WindowEventMap>(element: Window, eventname: K, options?: any) => IObservable<WindowEventMap[K]>)
  | (<K extends keyof DocumentEventMap>(element: Window, eventname: K, options?: any) => IObservable<DocumentEventMap[K]>)
  | (<K extends keyof HTMLElementEventMap>(element: Window, eventname: K, options?: any) => IObservable<HTMLElementEventMap[K]>)

export const fromEvent = <T>(element: HTMLElement | Window | Document | any, eventname: string, options?: any) => mkObservable<T>(observer => {
  const callback = (e: any) => observer.next(e);
  element.addEventListener(eventname, callback, options);
  return () => element.removeEventListener(eventname, callback);
})
