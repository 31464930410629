import {IObservable, mkObservable} from "./Observable";
export const scan = <A, B>(f: ((b: B, a: A) => B), init: B) => (source: IObservable<A>) => mkObservable<B>(observer => {
  var last: B;
  var has_last = false;
  const mapObserver = {
    next: (x: A) => {
      last = f(has_last ? last : init, x);
      has_last = true;
      return observer.next(last);
    },
    error: (err: any) => observer.error(err),
    complete: () => observer.complete()
  };
  return source.subscribe(mapObserver);
});
