import { IObservable, mkObservable, Observer } from "./Observable";
export const combineLatestAll = (obs: IObservable<any>[]) => mkObservable((observer: Observer<any[]>) => {
  const checkArr = [...new Array(obs.length)]
  const latestValues: any[] = checkArr.map(() => undefined)
  obs.map((o, i) => {
    o.subscribe({
      next: v => {
        latestValues[i] = v

        if (latestValues.every(a => typeof a != 'undefined')) {
          observer.next(latestValues);
        }
      },
      error: err => observer.error(err),
      complete: () => observer.complete()
    });
  })
});


export const combineLatestAllHash = <T>(obs: { [Key in keyof T]: IObservable<any> }) => mkObservable((observer: Observer<{ [Key in keyof T]: any }>) => {
  const keys = [...Object.keys(obs)]
  const latestValues = keys.map(key => [key, undefined]).reduce((o, [key, value]) => {
    (o as any)[key as string] = value;
    return o
  }, {} as { [Key in keyof T]: any })
  keys.map(key => ({ key, obs: (obs as any)[key] as IObservable<any> }))
    .forEach(({ key, obs }) => {
      obs.subscribe({
        next: v => {
          (latestValues as any)[key] = v;
          observer.next(latestValues)
        },
        error: err => observer.error(err),
        complete: () => observer.complete()
      });
    })
});
