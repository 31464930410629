import { IFlowEvent, IOptInFlowName, PacmanEvent, PacmanEventsMap, PacmanEvenWithRelTime } from "./types";
import { getDocumentScroll, getEventPos, getRelativeTime, getTouchMoveDistance, getViewportSize } from "./utils";
import { mkVisibilityChange } from "../Streams/Visibility";
import { map } from "../Observables/map";
import { fromEvent } from "../Observables/fromEvent";
import { IObservable } from "../Observables/Observable";
import { take } from "../Observables/take";
import { mkGenericDeviceMotion, DeviceMotion, toDeviceMotionIncludingGravity } from "../Streams/DeviceMotion"
import { sumDeviceMotion } from "../Streams/SumDeviceMotion";
import { filter } from "../Observables/filter";
import { mk } from "../Observables/mk";
import { zip } from "../Observables/zip";
import { combineLatestAll } from "../Observables/combineLatestAll";
import { mergeAll } from "../Observables/mergeAll";
import { share } from "../Observables/share";
import { startWith } from "../Observables/startWith";
import { timer } from "../Observables/timer";
import { buffer } from "../Observables/buffer";
import { sampleTime } from "../Observables/sampleTime";
import { mkReplayObservable } from "../Observables/mkReplayObservable";
import { mkPageView } from "./mkPageView";
import { catchError } from "../Observables/catchError";
import { mkBattery } from "../Streams/Battery";
import { when } from "../Observables/when";
import Emitter from "../EventsDispatcher";
import { act } from "../Observables/act";

type NotSupported<T> = {
  tag: 'supported', value: T
} | { tag: 'not-supported', error: any }

const rescue = <T>(source: IObservable<T>) => source.pipe(
  map<T, NotSupported<T>>(value => ({ tag: 'supported', value })),
  catchError<NotSupported<T>>(error => mk({ tag: 'not-supported', error }))
)

const MIN_SUM_DEVICE_MOTION = 10;

const $unload = fromEvent<BeforeUnloadEvent>(window, "beforeunload");

const $battery: IObservable<{
  t: 'battery-not-supported'
} | {
  t: 'battery',
  a: { p: [number, number] }
}>
  = mkBattery().pipe(rescue, take(1), map(t => t.tag === 'not-supported' ? { t: 'battery-not-supported' } : {
    t: 'battery', a: {
      p: [+t.value.charging, Math.round(t.value.level * 100)]
    }
  }));

const $visibilities = mkVisibilityChange().pipe(
  rescue,
  filter(d => d.tag === "supported"),
  map(d => ({ t: d.tag === "supported" ? !d.value ? "hidden" : "visible" : null }))
)


const $blur = fromEvent<FocusEvent>(window, "blur").pipe(map(_d => ({
  t: "blur",
  a: {
    e: "window"
  }
})))

const $focus = fromEvent(window, "focus").pipe(map(_d => ({
  t: "focus",
  a: {
    e: "window"
  }
})))

const $resize = fromEvent<WindowEventMap["resize"]>(window, "resize").pipe(map(event => ({
  t: event.type,
  a: {
    p: getViewportSize()
  }
})))

function mkDocumentEvent(eventName: string, t: string) {
  return fromEvent<FocusEvent>(document, eventName).pipe(map((d: FocusEvent) => ({
    t: t,
    a: {
      e: (d.target as HTMLElement | null)?.id || ""
    }
  })))
}

const $focusIn = mkDocumentEvent("focusin", "focus");
const $focusOut = mkDocumentEvent("focusout", "blur");
const $submit = mkDocumentEvent("submit", "submit");
const $fChange = mkDocumentEvent("change", "fchange").pipe(take(1));
const $touchEnd = fromEvent<DocumentEventMap["touchend"]>(document, "touchend")
const $touchStart = fromEvent<DocumentEventMap["touchstart"]>(document, "touchstart")
const $touchMove = fromEvent<DocumentEventMap["touchmove"]>(document, "touchmove")

const $fTouch = $touchStart.pipe(
  take(1),
  map(_d => ({ t: "ftouch" }))
)
const $genericDeviceMotion = mkGenericDeviceMotion();
const $deviceMotionIncludingGravity = toDeviceMotionIncludingGravity($genericDeviceMotion)
const $fMotion = sumDeviceMotion($deviceMotionIncludingGravity).pipe(
  map(d => Math.sqrt(d.x * d.x + d.y * d.y + d.z * d.z) > MIN_SUM_DEVICE_MOTION)
  , filter(d => d)
  , take(1)
  , map(_d => ({ t: 'fmotion' }))
)

const $load = fromEvent<WindowEventMap["load"]>(window, "load").pipe(
  take(1)
  , map(_d => ({
    t: "full_load",
    a: {
      p: getViewportSize()
    }
  })))

const isDocumentInteractive = () => document.readyState === "interactive" || document.readyState === "complete"


const $domContentLoaded = (isDocumentInteractive() ? mk("") : fromEvent<DocumentEventMap["readystatechange"]>(document, "readystatechange").pipe(map(d => ""))).pipe(
  map(_d => isDocumentInteractive())
  , filter(d => d)
  , take(1)
  , map(_d => {
    return {
      t: "html_load",
      a: {
        p: getViewportSize()
      }
    }
  }))


const $clicks = zip<TouchEvent, TouchEvent>($touchStart)($touchEnd).pipe(
  share(),
  filter(x =>
    x[1].timeStamp - x[0].timeStamp < 350 &&
    x[0].touches.length === 1 &&
    x[1].touches.length === 0
  ),
  map(event => {
    return {
      t: "click",
      a: {
        e: (event[0].touches[0].target as HTMLElement)?.id || "",
        p: getEventPos(event[0].touches[0])
      }
    };
  })
);

const $touchMotion = when($touchEnd)(combineLatestAll([$touchStart, $touchMove])).pipe(
  map(([s, e]: [TouchEvent, TouchEvent]) => {
    return {
      t: "touchmove",
      a: {
        p: getTouchMoveDistance([s.touches[0], e.touches[0]])
      }
    }
  })
)

const $mousedown = fromEvent<DocumentEventMap["mousedown"]>(document, "mousedown").pipe(
  map(event => {
    return {
      t: "mouseclick",
      a: {
        e: (event.target as HTMLElement)?.id || "",
        p: getEventPos(event)
      }
    };
  })
);


const $scrolls = fromEvent<UIEvent>(window, 'scroll').pipe(
  sampleTime(500)
  , map(event => ({
    t: event.type,
    a: {
      p: getDocumentScroll()
    }
  }))
)



export default function mkPacmanClient({ serverUrl, rockmanId, impressionNumber, signalInterval = 2000, logThings, }: { serverUrl: string, rockmanId: string, impressionNumber: number, signalInterval?: number, logThings?: boolean }) {

  const MIN_TOUCH_MOVE_DISTANCE = 50;

  var batch = 0;
  function send(data: object | Array<object>) {
    const payload = JSON.stringify({
      r: rockmanId,
      m: impressionNumber,
      b: batch++,
      d: data
    })
    if (typeof navigator.sendBeacon != 'undefined') {
      navigator.sendBeacon(serverUrl, payload)
    } else {
      var xhr = new XMLHttpRequest();
      xhr.open("POST", serverUrl, true);
      xhr.send(payload);
    }
  }

  const log = (() => {
    if (!!logThings) {
      const pre = document.createElement('pre');
      pre.style.whiteSpace = 'pre';
      pre.style.overflowWrap = 'normal';
      pre.style.width = '100vw';
      pre.style.overflow = 'auto';
      pre.style.margin = '0';
      pre.style.padding = '0';
      pre.style.position = 'fixed';
      pre.style.top = '0';
      pre.style.left = '0';
      document.body.appendChild(pre)
      var arr: string[] = []
      return (o: object | string | Array<any>) => {
        console.log(o)
        const txt = typeof o == 'string' ? o : JSON.stringify(o, null, 2)
        arr.unshift(txt)
        pre.innerHTML = arr.slice(0, 100).join("\n")
      }
    } else {
      return (_txt: object | string | Array<any>) => { }
    }
  })();


  send(mkPageView())

  const $flowEvents = mkReplayObservable<any>();
  const $custom = mkReplayObservable<any>();
  const $everySecond = startWith(0, timer(signalInterval).pipe<number>(map<number, number>(i => i + 1)));
  const $signal = mergeAll([$everySecond, $unload])
  const $buffer = buffer($signal)

  const events = new Emitter<PacmanEventsMap>();


  var humanDetected = false;

  mergeAll([
    $load
    , $domContentLoaded
    , $resize
    , $blur
    , $focus, $focusIn, $focusOut, $visibilities
    , $clicks
    , $scrolls
    , $touchMotion.pipe(act(t => {
      const deltaX = t?.a?.p[0], deltaY = t?.a?.p[1]
      events.emit('touchmove', ({ deltaX, deltaY }))
      if(!humanDetected && Math.sqrt(deltaX*deltaX + deltaY*deltaY) >= MIN_TOUCH_MOVE_DISTANCE) {
        events.emit('manusia', {});
        humanDetected = true;
      }
    }))
    , $mousedown
    , $battery.pipe(act(t => {
      if (t.t === 'battery') {
        const charging = t.a?.p[0], percent = t.a?.p[1];
        events.emit('battery', {
          charging,
          percent
        })
      }
    }))
    , $fTouch.pipe(act(t => {
      events.emit('ftouch', {})
    }))
    , $fChange
    , $fMotion.pipe(act(t => {
      events.emit('fmotion', {})
      if(!humanDetected) {
        events.emit('manusia', {});
        humanDetected = true;
      }
    }))
    , $submit
    , $flowEvents, $custom, $unload.pipe(map(_ => ({ t: 'unload' })))
  ].map((o: IObservable<PacmanEvent>) => o.pipe(
    map<PacmanEvent, PacmanEvenWithRelTime>(v => ({ ...v, s: getRelativeTime() }))
  ))).pipe<PacmanEvenWithRelTime[]>($buffer).subscribe({
    next: o => {
      log(o)
      send(o)
    }, error: (ex: any) => {
      console.error(ex)
      log(ex.toString())
    },
    complete: () => {
      log('completed!')
    }
  })


  var flowEventNumber = 0;
  const sendFlowEvent = (flowEvent: IFlowEvent) => {
    $flowEvents.next({
      t: 'flow_event',
      a: {
        number: flowEventNumber++,
        ...flowEvent
      }
    })
    if (typeof window != "undefined" && !!window.dataLayer && !!window.dataLayer.push) {
      window.dataLayer.push({ ...flowEvent, event: "gaEvent" });
    }
  }

  const sendCustomEvent = (typeName: string, eventName: string) => {
    $custom.next({
      t: typeName,
      a: {
        e: eventName
      }
    })
  };

  const sendOptInFlowEvent = (optInFlowName: IOptInFlowName) => {
    $custom.next({
      t: "get_sub_method",
      a: {
        e: optInFlowName
      }
    })
  };

  const onAdvanceInFlow = (flow: string, action: string, args?: any) => {
    const gaEvent = {
      category: "Flow",
      action: `advance`,
      label: `${action}`,
      args
    };
    sendFlowEvent(gaEvent)
  }
  const onAdvanceInPreFlow = (label: string, args?: any) => {
    const gaEvent = { category: "Pre-Flow", action: "advance", label, args };
    sendFlowEvent(gaEvent)
  }



  const predefinedFlowEvents = {
    onAdvanceInPreFlow,
    onSubmitMSISDN: ({ msisdn }: { msisdn: string }) => onAdvanceInFlow('any-flow', 'msisdn-submitted', { msisdn }),
    onSubmitPIN: ({ pin, msisdn }: { pin: string, msisdn?: string }) => onAdvanceInFlow('any-flow', 'pin-submitted', { msisdn, pin })
  }

  return {
    log,
    events,
    sendFlowEvent,
    sendCustomEvent,
    sendOptInFlowEvent,
    predefinedFlowEvents
  }
}

