import { mkObservable } from "../Observables/Observable";

export type BatterStatus = {
  charging: boolean
  chargingTime: number
  level: number
}

export const mkBattery = () => mkObservable<BatterStatus>(observer => {
  if (!navigator.getBattery) {
    observer.error('navigator.getBattery not supported')
  } else {
    navigator.getBattery().then(battery => {
      const next = () => {
        observer.next({
          charging: battery.charging,
          chargingTime: battery.chargingTime,
          level: battery.level
        })
      }

      next();
      battery.addEventListener("chargingchange", next);
      battery.addEventListener("chargingtimechange", next);
      battery.addEventListener("dischargingtimechange", next);
      battery.addEventListener("levelchange", next);
    }).catch(ex => {
      observer.error('navigator.getBattery promise failed')
    })
  }
})