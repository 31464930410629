import { mkObservable } from "./Observable";
export const timer = (interval: number, limit = Infinity) => mkObservable<number>(observer => {
  var i = 0;
  const t = setInterval(() => {
    observer.next(i++);
    if (i === limit) {
      observer.complete();
      clearInterval(t);
    }
  }, interval);
  return () => clearInterval(t);
});
