import { IObservable, mkObservable } from "./Observable";
export const buffer = <T>(notifier: IObservable<any>) => (source: IObservable<T>) => mkObservable<T[]>(observer => {
  var buff: T[] = [];
  var i = 0;
  source.subscribe({
    next: i => buff.push(i),
    error: err => observer.error(err),
    complete: () => void 8// observer.complete()
  });
  notifier.subscribe({
    next: () => {
      if(buff.length > 0) {
        observer.next([...buff]);
        buff = [];
      }
    },
    error: err => observer.error(err),
    complete: () => observer.complete()
  });
});
