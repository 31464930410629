import { IObservable, mkObservable } from "./Observable";
export const sampleTime = <T>(t: number) => (source: IObservable<T>) => mkObservable<T>(observer => {
  var last: T;
  var hasNewValue = false;
  const interval = setInterval(() => {
    if (hasNewValue) {
      observer.next(last);
      hasNewValue = false;
    }
  }, t);
  source.subscribe({
    next: a => {
      hasNewValue = true;
      last = a;
    },
    error: err => observer.error(err),
    complete: () => {
      clearInterval(interval);
      observer.complete();
    }
  });
});
