import { IObservable, mkObservable, Observable, Observer } from "./Observable";

type ReplayObservable<T> = IObservable<T> & {
  next: (t: T) => void
}

export const mkReplayObservable = <T>() => {
  var _observer : Observer<T>
  var res = mkObservable<T>(observer => {
    _observer = observer
  });
  (res as any)['next'] = (t: T) => _observer.next(t)
  return res as ReplayObservable<T> 
}