import { IObservable, mkObservable, Observer } from "./Observable";
export const mergeAll = (obs: IObservable<any>[]) => mkObservable((observer: Observer<any[]>) => {
  //TODO: count completed observables
  obs.map(o => {
    o.subscribe({
      next: v => {
        observer.next(v);
      },
      error: err => observer.error(err),
      complete: () => void 6 //observer.complete()
    });
  })
});

