import {IObservable, mkObservable} from "./Observable";
export const take = <T>(limit: number) => (source: IObservable<T>): IObservable<T> => mkObservable(observer => {
  var i = 0;
  return source.subscribe({
    next: x => {
      i++;
      
      if (i > limit) {
        observer.complete();
      } else {
        observer.next(x);
      }
    },
    error: err => observer.error(err),
    complete: () => observer.complete()
  });
});
