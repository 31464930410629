type Handler<T> = (arg: T) => void;

export default class Emitter<T> {

  _callbacks: { [key: string]: Handler<any>[] } = {}

  addEventListener<K extends keyof T>(event: K, fn: Handler<T[K]>) {
    (this._callbacks['$' + event] = this._callbacks['$' + event] || [])
      .push(fn);
    return this;
  };


  once<K extends keyof T>(event: K, fn: Handler<T[K]>) {
    const self = this
    function on(arg: T[K]) {
      self.removeEventListener(event, on);
      fn(arg)
    }
    on.fn = fn;
    this.addEventListener(event, on);
    return this;
  };


  removeEventListener<K extends keyof T>(event: K, fn: Handler<T[K]>) {

    // all
    if (0 == arguments.length) {
      this._callbacks = {};
      return this;
    }

    // specific event
    var callbacks = this._callbacks['$' + event];
    if (!callbacks) return this;

    // remove all handlers
    if (1 == arguments.length) {
      delete this._callbacks['$' + event];
      return this;
    }

    // remove specific handler
    var cb;
    for (var i = 0; i < callbacks.length; i++) {
      cb = callbacks[i];
      if (cb === fn || fn === (cb as any)['fn']) {
        callbacks.splice(i, 1);
        break;
      }
    }

    // Remove event specific arrays for event types that no
    // one is subscribed for to avoid memory leak.
    if (callbacks.length === 0) {
      delete this._callbacks['$' + event];
    }

    return this;
  };


  emit<K extends keyof T>(event: K, arg: T[K]) {
    this._callbacks = this._callbacks || {};

    const callbacks = this._callbacks['$' + event];

    if (callbacks) {
      const callbacksCopy = callbacks.slice(0);
      for (var i = 0, len = callbacksCopy.length; i < len; ++i) {
        callbacksCopy[i](arg)
      }
    }

    return this;
  };

  listeners<K extends keyof T>(event: K) {
    return this._callbacks['$' + event] || [];
  }

  hasListeners<K extends keyof T>(event: K) {
    return !!this.listeners(event).length;
  };
}