import {IObservable} from "../Observables/Observable";
import { DeviceMotion, DeviceMotionXYZ } from "./DeviceMotion";
import { share } from "../Observables/share";
import { scan } from "../Observables/scan";
import { map } from "../Observables/map";

const smallD = (a: number, b: number) => {
  const d = a - b;
  if (d < 0.1) {
    return 0;
  } else {
    return d;
  }
};

export const sumDeviceMotion = (deviceMotion$: IObservable<DeviceMotion>) =>
  deviceMotion$.pipe(
    share(),
    scan<DeviceMotion, { delta: DeviceMotionXYZ, last: DeviceMotionXYZ | null }>(
      ({ delta, last }, a) => {
        if (!last) {
          return { delta: delta, last: a };
        }
        return {
          delta: {
            x: smallD(Math.max(last.x, a.x), Math.min(last.x, a.x)),
            y: smallD(Math.max(last.y, a.y), Math.min(last.y, a.y)),
            z: smallD(Math.max(last.z, a.z), Math.min(last.z, a.z))
          },
          last: a
        };
      },
      {
        delta: { x: 0, y: 0, z: 0 },
        last: null
      }
    ),
    map<{ delta: DeviceMotionXYZ }, DeviceMotionXYZ>(x => x.delta),
    scan<DeviceMotionXYZ, DeviceMotionXYZ>(
      (acc, a) => ({
        x: acc.x + a.x,
        y: acc.y + a.y,
        z: acc.z + a.z
      }),
      { x: 0, y: 0, z: 0 }
    )
  );