import {IObservable} from "../Observables/Observable";
import { fromEvent } from "../Observables/fromEvent";
import { share } from "../Observables/share";
import { map } from "../Observables/map";

export type DeviceMotionXYZ = {
  x: number;
  y: number;
  z: number;
}

export type DeviceMotion = DeviceMotionXYZ & {
  interval: number;
}

export type GenericDeviceMotion = {
  acceleration: {
    x: number;
    y: number;
    z: number;
  };
  accelerationIncludingGravity: {
    x: number;
    y: number;
    z: number;
  };
  interval: number;
};

export const supportsDeviceMotion = () => (
  "ondevicemotion" in window
)

export const mkGenericDeviceMotion = () => fromEvent<GenericDeviceMotion>(window, "devicemotion", true);

export const toDeviceMotion = (genericDeviceMotion$: IObservable<GenericDeviceMotion>) =>
  genericDeviceMotion$.pipe(
    share(),
    map<GenericDeviceMotion, DeviceMotion>(event => ({
      x: event.acceleration.x,
      y: event.acceleration.y,
      z: event.acceleration.z,
      interval: event.interval
    }))
  );

export const toDeviceMotionIncludingGravity = (genericDeviceMotion$: IObservable<GenericDeviceMotion>) =>
  genericDeviceMotion$.pipe(
    share(),
    map<GenericDeviceMotion, DeviceMotion>(event => ({
      x: event.accelerationIncludingGravity.x,
      y: event.accelerationIncludingGravity.y,
      z: event.accelerationIncludingGravity.z,
      interval: event.interval
    }))
  );
